import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import {
  createTicket,
  createTicketCash,
  updateTicket,
  updateTicketCash,
  loadEvent,
  loadTicketByUsage,
  loadEventsWithCombo,
  resetActions
} from 'state/modules/events'
import { loadCommissions } from 'state/modules/mercadoPago'
import { PaperContainer } from '~/views/shared'
import { useStyles } from './TicketsCreatePage.style'
import * as moment from 'moment'
import {
  disableFormDate,
  getPaymentGatewayAccreditationDays,
  getPaymentGatewayCommission
} from '../TicketsUtils/TicketsUtils'
import { EventTicketForm } from 'views/pages/EventsPage/components'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import {
  TICKET_USAGE,
  COMMISSION_TYPE,
  COMMISSION_USED,
  TICKET_VERSION
} from 'config/constants'

export const TicketsCreatePage = ({ onSuccess }) => {
  const {
    event,
    pendingTicket,
    actionPending,
    actionSuccess,
    pendingEventsCombo,
    ticket
  } = useSelector((state) => state.events)
  const { commissions, pending } = useSelector((state) => state.mercadoPago)
  const disableForm = useCallback(() => disableFormDate(ticket), [ticket])
  const paymentCommission = useCallback(
    () =>
      getPaymentGatewayCommission(
        commissions,
        ticket?.PaymentGatewayCommission
      ),
    [commissions, ticket]
  )
  const accreditationDays = useCallback(
    () =>
      getPaymentGatewayAccreditationDays(
        commissions,
        ticket?.PaymentGatewayAccreditationDays
      ),
    [commissions, ticket]
  )
  const classes = useStyles()
  const currentURL = window.location.href
  const isCloned = currentURL?.includes('cloned')
  const { eventId, ticketUsage, originalId } = useParams()
  const defaultCommissionMp = event?.Organizer?.DefaultCommissionMp
  const [showDialog, setShowDialog] = useState(false)
  const [newValues, setNewValues] = useState()
  const paymentGatewayCommission = defaultCommissionMp
    ? defaultCommissionMp
    : commissions?.mpCommissions[0]?.Fee || 0
  const [values, setValues] = useState({
    Name: '',
    Price: 0,
    Remaining: '',
    ServiceCharge: 0,
    SaleStartDate: moment('00:00', 'hh:mm'),
    SaleEndDate: moment('23:30', 'HH:mm'),
    Cost: 0,
    TicketUsage: ticketUsage || 0,
    PaymentGatewayCommission: paymentGatewayCommission,
    PaymentGatewayAccreditationDays: commissions
      ? commissions.mpCommissions[0].AccreditationDays
      : 0,
    Commission: event?.Organizer?.Commission || 35,
    CommissionCash: 0,
    Active: true,
    AllowRemoteSell: false,
    IsTransferable: ticketUsage !== TICKET_USAGE.INVITATION,
    DisabledDatesForm: true,
    AllowedToRole: 0,
    MaxByReseller: null,
    ShowRemaining: true,
    ComboTickets: '',
    Rules: '',
    RoleAdministrator: true,
    RoleProducer: true,
    RoleRRPP: false,
    MaxQuantityToSale: '',
    PercentageCost: 0.00001,
    TicketPrice: 0,
    ByPercentReseller: false,
    ByPercentPlatform: true,
    Billable: parseInt(ticketUsage) === TICKET_USAGE.SALE,
    Version:
      parseInt(ticketUsage) === TICKET_USAGE.SALE
        ? TICKET_VERSION.V2
        : TICKET_VERSION.V1,
    Commissions: [
      {
        CommissionType: COMMISSION_TYPE.PLATFORM,
        CommissionUsed: COMMISSION_USED.BY_PERCENT,
        Commission: event?.Organizer?.Commission || 0,
        CommissionVat: commissions ? commissions.vatList[0] : '',
        CommissionWithTaxAmount: 0
      },
      {
        CommissionType: COMMISSION_TYPE.PAYMENT_GATEWAY,
        CommissionUsed: COMMISSION_USED.BY_PERCENT,
        Commission: paymentGatewayCommission,
        CommissionVat: commissions ? commissions.vatList[0] : '',
        CommissionWithTaxAmount: 0
      },
      {
        CommissionType: COMMISSION_TYPE.RESELLER,
        CommissionUsed: COMMISSION_USED.BY_PERCENT,
        Commission: 0,
        CommissionVat: 0,
        CommissionWithTaxAmount: 0
      }
    ]
  })
  const dispatch = useDispatch()

  const handleRedirect = () => {
    onSuccess(eventId)
  }

  const handleCreateTicket = async (values) => {
    try {
      if (!values.DisabledDatesForm) {
        values.SaleStartDate = null
        values.SaleEndDate = null
      }

      if (values.ComboTickets !== '') {
        values.ComboTickets = values.ComboTickets.split(',')
        values.ComboTickets = values.ComboTickets.map((i) => Number(i))
      }
      if (ticketUsage.toString() === TICKET_USAGE.CASH.toString()) {
        await dispatch(createTicketCash(eventId, values))
      } else {
        await dispatch(createTicket(eventId, values))
      }
      onSuccess(eventId)
      showSnackbarSuccess('Ticket Creado!')
    } catch (error) {
      //values.ComboTickets = values.ComboTickets.toString()
      if (values.Commissions.length > 0) {
        values.Commissions = values.Commissions.map((v) => {
          return {
            ...v,
            Commission: v.Commission * 100
          }
        })
      }
      showSnackbarError(error?.response?.data?.Message)
      //await dispatch(loadTicket(eventId, originalId))
    } finally {
      dispatch(resetActions())
      setShowDialog(false)
    }
  }

  const handleSubmit = async (v) => {
    setNewValues(v)
    try {
      if (isCloned) {
        if (ticket?.Active && ticket?.IsEditable) {
          setShowDialog(true)
        } else {
          handleCreateTicket(v)
        }
      } else {
        handleCreateTicket(v)
      }
    } catch (error) {
      console.error('Failed to submit or update ticket:', error)
      showSnackbarError('Error actualizando ticket: ' + error)
    }
  }

  const handleCreateConfirmation = async () => {
    try {
      if (ticket?.TicketUsage === TICKET_USAGE.CASH) {
        await dispatch(
          updateTicketCash(eventId, originalId, {
            ...ticket,
            Active: false
          })
        )
      } else {
        await dispatch(
          updateTicket(eventId, originalId, { ...ticket, Active: false })
        )
      }
    } catch (error) {
      console.error('Failed to submit or update ticket:', error)
      showSnackbarError('Error actualizando ticket: ' + error)
    }
    handleCreateTicket(newValues)
  }

  const ticketUsageLabel = (usage) => {
    switch (parseInt(usage)) {
      case TICKET_USAGE.SALE:
        return 'Venta Online'
      case TICKET_USAGE.DRAW:
        return 'Sorteo'
      case TICKET_USAGE.CASH:
        return 'Venta en Efectivo'
      case TICKET_USAGE.INVITATION:
        return 'Invitación'
      case TICKET_USAGE.SWAP:
        return 'Canje'
      case TICKET_USAGE.COMBO:
        return 'Combo'
      case TICKET_USAGE.FREE:
        return 'Gratuito'
      case TICKET_USAGE.SERVICE_CHARGE_ONLY:
        return 'Solo Cargo por Servicio'
      default:
        return ''
    }
  }

  const getTicketPrice = (commissions, cost) => {
    if (commissions != null) {
      let ticketPrice = 0
      commissions.forEach((v) => {
        const withTaxAmount = Math.floor(v.CommissionWithTaxAmount * 100) / 100
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          ticketPrice = parseFloat(cost) + parseFloat(withTaxAmount)
        }
      })
      return ticketPrice
    }
  }

  const getServiceCharge = (commissions, ticketUsed) => {
    if (commissions != null) {
      let serviceCharge = 0
      commissions.forEach((v) => {
        const withTaxAmount = Math.floor(v.CommissionWithTaxAmount * 100) / 100
        if (v.CommissionType !== COMMISSION_TYPE.RESELLER) {
          if (ticketUsed === TICKET_USAGE.SALE) {
            serviceCharge += parseFloat(withTaxAmount)
          } else {
            if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
              serviceCharge += withTaxAmount
            }
          }
        }
      })
      return serviceCharge
    }
  }

  const myRound = (num) => {
    let exp = Math.pow(10, 2)
    return parseInt(num * exp, 10) / exp
  }

  const getComboTickets = (comboTicket) => {
    if (comboTicket?.length > 0) {
      let ticketsIds = ''
      comboTicket.forEach((t) => {
        ticketsIds += `${t.TicketId},`
      })
      return ticketsIds.substring(0, ticketsIds.length - 1)
    }
  }

  const calculateProfitMargin = (cost, price) => {
    return (((cost * 100) / price) * 100) / 100
  }

  const byPercentReseller = (commissions) => {
    if (commissions != null) {
      let isByPercent = false
      commissions.forEach((v) => {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          if (v.CommissionUsed === COMMISSION_USED.BY_PERCENT) {
            isByPercent = true
          }
        }
      })
      return isByPercent
    }
  }

  const byPercentPlatform = (commissions) => {
    if (commissions != null) {
      let isByPercent = false
      commissions.forEach((v) => {
        if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
          if (v.CommissionUsed === COMMISSION_USED.BY_PERCENT) {
            isByPercent = true
          }
        }
      })
      return isByPercent
    }
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEventsWithCombo())
      await dispatch(loadCommissions())
      await dispatch(loadTicketByUsage(eventId, TICKET_USAGE.COMBO))
      await dispatch(loadEvent(eventId))
    }
    fetchData().then()
  }, [dispatch, eventId])

  useEffect(() => {
    if (ticket != null && isCloned) {
      setValues((values) => ({
        ...values,
        Name: ticket?.Name,
        Price: ticket?.Price || 0,
        Remaining: ticket?.Remaining || 0,
        TicketPrice: getTicketPrice(ticket?.Commissions, ticket?.Cost) || 0,
        ServiceCharge:
          getServiceCharge(ticket?.Commissions, ticket?.TicketUsage) || 0,
        SaleStartDate: ticket?.SaleStartDate || moment('00:00', 'hh:mm'),
        SaleEndDate: ticket?.SaleEndDate || moment('23:30', 'HH:mm'),
        Cost: ticket?.Cost || 0,
        TicketUsage: ticket?.TicketUsage || 0,
        PaymentGatewayCommission:
          paymentCommission(ticket?.PaymentGatewayCommission) || 0,
        PaymentGatewayAccreditationDays:
          accreditationDays(ticket?.PaymentGatewayAccreditationDays) || 0,
        Commission:
          myRound(
            ticket?.Price -
              (ticket?.Price * ticket?.PaymentGatewayCommission) / 100 -
              ticket?.Cost
          ) || 0,
        CommissionCash: ticket?.Price - ticket?.Cost,
        Active: ticket?.Active,
        AllowRemoteSell: ticket?.AllowRemoteSell || false,
        IsTransferable: ticket?.IsTransferable,
        DisabledDatesForm: disableForm(),
        AllowedToRole: ticket?.AllowedToRole || 0,
        MaxByReseller: ticket?.MaxByReseller || null,
        ShowRemaining: ticket?.ShowRemaining,
        ComboTickets: getComboTickets(ticket?.ComboTicketModel) || '',
        ComboTicketModel: ticket?.ComboTicketModel || [],
        Rules: ticket?.Rules || '',
        RoleAdministrator: !!(ticket?.AllowedToRole & 1),
        RoleProducer: !!(ticket?.AllowedToRole & 4),
        RoleRRPP: !!(ticket?.AllowedToRole & 8),
        MaxQuantityToSale: ticket?.MaxQuantityToSale || '',
        PercentageCost:
          calculateProfitMargin(ticket?.Cost, ticket?.Price) || '',
        ByPercentReseller: byPercentReseller(ticket?.Commissions) || false,
        ByPercentPlatform: byPercentPlatform(ticket?.Commissions) || false,
        Billable: ticket?.Billable,
        Commissions: ticket?.Commissions || []
      }))
    }
  }, [ticket, accreditationDays, paymentCommission, disableForm, isCloned])

  useEffect(() => {
    if (event != null && !isCloned) {
      setValues((values) => ({
        ...values,
        PaymentGatewayCommission: paymentGatewayCommission,
        Commissions: [
          {
            CommissionType: COMMISSION_TYPE.PLATFORM,
            CommissionUsed: COMMISSION_USED.BY_PERCENT,
            Commission: event?.Organizer?.Commission || 0,
            CommissionVat: commissions ? commissions.vatList[0] : '',
            CommissionWithTaxAmount: 0
          },
          {
            CommissionType: COMMISSION_TYPE.PAYMENT_GATEWAY,
            CommissionUsed: COMMISSION_USED.BY_PERCENT,
            Commission: paymentGatewayCommission,
            CommissionVat: commissions ? commissions.vatList[0] : '',
            CommissionWithTaxAmount: 0
          },
          {
            CommissionType: COMMISSION_TYPE.RESELLER,
            CommissionUsed: COMMISSION_USED.BY_PERCENT,
            Commission: 0,
            CommissionVat: 0,
            CommissionWithTaxAmount: 0
          }
        ]
      }))
    }
  }, [event, commissions, paymentGatewayCommission, isCloned])

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}
        </Typography>
      )}
      <PaperContainer
        title={`Crear Ticket (${ticketUsageLabel(ticketUsage)}) ${
          isCloned ? '- (Clonado)' : ''
        }`}>
        {pendingTicket || pending || pendingEventsCombo ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <EventTicketForm
            values={values}
            actionText='Crear'
            isEditable={true}
            handleSubmit={handleSubmit}
            actionPending={actionPending}
            actionSuccess={actionSuccess}
            onCancel={handleRedirect}
          />
        )}
      </PaperContainer>
      <Dialog open={showDialog}>
        <DialogTitle>¡IMPORTANTE!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Desea deshabilitar el ticket original?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCreateTicket(newValues)}
            color='primary'
            disabled={actionPending}>
            No
          </Button>
          <Button
            onClick={handleCreateConfirmation}
            pend
            color='primary'
            disabled={actionPending}>
            {actionPending ? (
              <CircularProgress size={24} color='secondary' />
            ) : (
              'Si'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TicketsCreatePage
