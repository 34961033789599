import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
  DialogContent
} from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { isNotNumber, round } from 'views/utils/functions'
import { useStyles } from './CommissionFormDialog.style'

const validationSchema = Yup.object().shape({
  Commission: Yup.string()
    .required('Es requerido ingresar la comisión')
    .matches(/^\d+(\.\d+)?$/, 'La comisión debe ser un número positivo')
    .test(
      'is-valid-range',
      'La comisión no puede ser mayor a 100',
      (value) => parseFloat(value) <= 100
    ),
  Vat: Yup.string()
    .required('Es requerido ingresar el IVA')
    .matches(/^\d+(\.\d+)?$/, 'El IVA debe ser un número positivo')
    .test(
      'is-valid-range',
      'El IVA no puede ser mayor a 100',
      (value) => parseFloat(value) <= 100
    )
})

export const CommissionFormDialog = ({
  open,
  loading,
  handleSubmit,
  onCancel,
  values
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (!!values) {
      values.Commission = round(values.Commission * 100)
      values.Vat = round(values.Vat * 100)
    }
  }, [values])

  return (
    <Dialog open={open} onClose={!loading ? onCancel : null}>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={values}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              className={classes.formContainer}>
              <div>
                <div className={classes.fieldRow}>
                  <TextField
                    label={
                      (errors.Commission &&
                        touched.Commission &&
                        errors.Commission) ||
                      'Comisión %'
                    }
                    name='Commission'
                    type='string'
                    onKeyPress={(e) => isNotNumber(e)}
                    onKeyDown={(e) => isNotNumber(e)}
                    value={values.Commission}
                    inputProps={{
                      maxLength: 10
                    }}
                    disabled={loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Commission && Boolean(errors.Commission)}
                    fullWidth
                    size='small'
                    variant='outlined'
                    className={classes.field}
                  />
                  <TextField
                    label={(errors.Vat && touched.Vat && errors.Vat) || 'IVA %'}
                    name='Vat'
                    type='string'
                    onKeyPress={(e) => isNotNumber(e)}
                    onKeyDown={(e) => isNotNumber(e)}
                    value={values.Vat}
                    disabled={loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 10
                    }}
                    error={touched.Vat && Boolean(errors.Vat)}
                    fullWidth
                    size='small'
                    variant='outlined'
                    className={classes.field}
                  />
                </div>
              </div>
              <div className={classes.actionsContainer}>
                <Button
                  onClick={onCancel}
                  disabled={loading || isSubmitting}
                  autoFocus={false}
                  color='primary'>
                  Cancelar
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={loading || isSubmitting}
                  color='primary'
                  variant='contained'
                  startIcon={<Save />}
                  autoFocus={false}>
                  {loading || isSubmitting ? (
                    <CircularProgress size={24} color='secondary' />
                  ) : (
                    'Guardar'
                  )}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default CommissionFormDialog
