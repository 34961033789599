import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TICKET_USAGE } from 'config/constants'
import { TicketSaleForm } from './TicketSaleForm'
import { TicketSaleFormV2 } from './TicketSaleFormV2'
import { TicketServiceChargeForm } from './TicketServiceChargeForm'
import { TicketFreeForm } from './TicketFreeForm'
import { TicketCashForm } from './TicketCashForm'
import { DrawSwapComboForm } from './DrawSwapComboForm'
import { TicketInvitationForm } from './TicketInvitationForm'
import { ROLES, TICKET_VERSION } from 'config/constants'

export const EventTicketForm = ({
  values,
  actionText,
  drawFinalized,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const { ticketUsage } = useParams()
  const ticketType = parseInt(
    actionText === 'Crear' ? ticketUsage : values?.TicketUsage
  )
  const { user } = useSelector((state) => state.login)
  const { commissions } = useSelector((state) => state.mercadoPago)
  const rolesArray = user?.roles?.split(',') || []
  const currentURL = window.location.href
  const isCloned = currentURL?.includes('cloned')

  const getComboTickets = (comboTicketModel) => {
    if (comboTicketModel?.length > 0) {
      let comboTickets = []
      comboTicketModel.forEach((t) => {
        comboTickets = [
          ...comboTickets,
          {
            EventName: t.EventName,
            TicketName: `(${t.TicketId}) ${t.TicketName}`,
            TicketId: t.TicketId
          }
        ]
      })
      return comboTickets
    } else {
      return []
    }
  }
  const getIdsTickets = (idsTickets) => {
    let listIds = []
    if (idsTickets != null && idsTickets !== '') {
      listIds = idsTickets
        .toString()
        ?.split(',')
        .map((t) => parseInt(t))
      return listIds
    } else {
      return []
    }
  }

  return (
    <>
      {ticketType === TICKET_USAGE.SALE &&
        (values.Version === TICKET_VERSION.V1 ? (
          <TicketSaleForm
            values={values}
            isCloned={isCloned}
            getComboTickets={getComboTickets}
            getIdsTickets={getIdsTickets}
            commissionsMp={commissions?.mpCommissions}
            vatList={commissions?.vatList}
            isProducer={
              rolesArray?.includes(ROLES.PRODUCER) &&
              !rolesArray?.includes(ROLES.ADMIN)
            }
            actionText={actionText}
            isOldCommissions={values?.Commissions?.length === 0}
            onCancel={onCancel}
            actionPending={actionPending}
            actionSuccess={actionSuccess}
            handleSubmit={handleSubmit}
          />
        ) : (
          <TicketSaleFormV2
            values={values}
            isCloned={isCloned}
            getComboTickets={getComboTickets}
            getIdsTickets={getIdsTickets}
            commissionsMp={commissions?.mpCommissions}
            vatList={commissions?.vatList}
            isProducer={
              rolesArray?.includes(ROLES.PRODUCER) &&
              !rolesArray?.includes(ROLES.ADMIN)
            }
            actionText={actionText}
            isOldCommissions={values?.Commissions?.length === 0}
            onCancel={onCancel}
            actionPending={actionPending}
            actionSuccess={actionSuccess}
            handleSubmit={handleSubmit}
          />
        ))}

      {ticketType === TICKET_USAGE.CASH && (
        <TicketCashForm
          values={values}
          isCloned={isCloned}
          getComboTickets={getComboTickets}
          getIdsTickets={getIdsTickets}
          vatList={commissions?.vatList}
          isProducer={
            rolesArray?.includes(ROLES.PRODUCER) &&
            !rolesArray?.includes(ROLES.ADMIN)
          }
          actionText={actionText}
          isOldCommissions={values?.Commissions?.length === 0}
          onCancel={onCancel}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
          handleSubmit={handleSubmit}
        />
      )}

      {ticketType === TICKET_USAGE.INVITATION && (
        <TicketInvitationForm
          values={values}
          actionText={actionText}
          onCancel={onCancel}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
          handleSubmit={handleSubmit}
        />
      )}

      {(ticketType === TICKET_USAGE.COMBO ||
        ticketType === TICKET_USAGE.SWAP ||
        ticketType === TICKET_USAGE.DRAW) && (
        <DrawSwapComboForm
          values={values}
          actionText={actionText}
          onCancel={onCancel}
          drawFinalized={drawFinalized}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
          handleSubmit={handleSubmit}
        />
      )}

      {ticketType === TICKET_USAGE.FREE && (
        <TicketFreeForm
          values={values}
          actionText={actionText}
          handleSubmit={handleSubmit}
          onCancel={onCancel}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
        />
      )}
      {ticketType === TICKET_USAGE.SERVICE_CHARGE_ONLY && (
        <TicketServiceChargeForm
          values={values}
          isCloned={isCloned}
          getComboTickets={getComboTickets}
          getIdsTickets={getIdsTickets}
          actionText={actionText}
          handleSubmit={handleSubmit}
          onCancel={onCancel}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
        />
      )}
    </>
  )
}

export default EventTicketForm
