import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Typography } from '@material-ui/core'
import {
  loadTicketWithCombo,
  updateTicket,
  updateTicketCash,
  loadEvent,
  loadTicketByUsage,
  loadEventsWithCombo
} from 'state/modules/events'
import { loadCommissions } from 'state/modules/mercadoPago'
import { loadByEventPage } from '~/state/modules/draws'
import { PaperContainer } from '~/views/shared'
import { useStyles } from './TicketsUpdatePage.style'
import { EventTicketForm } from 'views/pages/EventsPage/components'
import moment from 'moment'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import {
  TICKET_USAGE,
  COMMISSION_TYPE,
  COMMISSION_USED
} from 'config/constants'

export const TicketsUpdatePage = ({ match, onSuccess }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    event,
    ticket,
    pendingTicket,
    actionPending,
    actionSuccess,
    pendingEventsCombo
  } = useSelector((state) => state.events)
  const { drawList } = useSelector((state) => state.draws)
  const { commissions, pending } = useSelector((state) => state.mercadoPago)

  const handleRedirect = () => {
    onSuccess(match.params.eventId)
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadCommissions())
      await dispatch(
        loadTicketWithCombo(match.params.eventId, match.params.ticketId)
      )
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEventsWithCombo())
      await dispatch(
        loadTicketByUsage(match.params.eventId, TICKET_USAGE.COMBO)
      )
      await dispatch(loadEvent(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadByEventPage(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  const drawFinalized = () => {
    let finalized = false
    drawList?.forEach((draw) => {
      if (draw.TicketTypeId.toString() === match.params.ticketId) {
        if (draw.Status === 2 || draw.Status === 'Finalized') {
          finalized = true
        }
      }
    })
    return finalized
  }

  const handleSubmit = async (values) => {
    try {
      if (!values.DisabledDatesForm) {
        values.SaleStartDate = null
        values.SaleEndDate = null
      }

      if (values.ComboTickets !== '') {
        values.ComboTickets = values.ComboTickets.split(',')
        values.ComboTickets = values.ComboTickets.map((i) => Number(i))
      } else {
        values.ComboTickets = []
      }
      if (ticket?.TicketUsage === TICKET_USAGE.CASH) {
        await dispatch(
          updateTicketCash(match.params.eventId, match.params.ticketId, values)
        )
      } else {
        await dispatch(
          updateTicket(match.params.eventId, match.params.ticketId, values)
        )
      }
      handleRedirect()
      showSnackbarSuccess('Ticket Actualizado!')
    } catch (error) {
      values.ComboTickets = values.ComboTickets.toString()
      if (values.Commissions.length > 0) {
        values.Commissions = values.Commissions.map((v) => {
          return {
            ...v,
            Commission: v.Commission * 100
          }
        })
      }
      showSnackbarError(error)
    }
  }

  const disableFormDate = () => {
    return ticket?.SaleStartDate == null
  }

  const myRound = (num) => {
    let exp = Math.pow(10, 2)
    return parseInt(num * exp, 10) / exp
  }

  const getPaymentGatewayCommission = (commission) => {
    if (commission == null || commission === 0) {
      return commissions ? commissions.mpCommissions[0].Fee : ''
    } else {
      return commission
    }
  }

  const getPaymentGatewayAccreditationDays = (commission) => {
    if (commission == null || commission === 0) {
      return commissions ? commissions.mpCommissions[0].AccreditationDays : ''
    } else {
      return commission
    }
  }

  const getComboTickets = (comboTicket) => {
    if (comboTicket?.length > 0) {
      let ticketsIds = ''
      comboTicket.forEach((t) => {
        ticketsIds += `${t.TicketId},`
      })
      return ticketsIds.substring(0, ticketsIds.length - 1)
    }
  }

  const calculateProfitMargin = (cost, price) => {
    return (((cost * 100) / price) * 100) / 100
  }

  const getTicketPrice = (commissions, cost) => {
    if (commissions != null) {
      let ticketPrice = 0
      commissions.forEach((v) => {
        const withTaxAmount = Math.floor(v.CommissionWithTaxAmount * 100) / 100
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          ticketPrice = parseFloat(cost) + parseFloat(withTaxAmount)
        }
      })
      return ticketPrice
    }
  }

  const getServiceCharge = (commissions, ticketUsed) => {
    if (commissions != null) {
      let serviceCharge = 0
      commissions.forEach((v) => {
        const withTaxAmount = Math.floor(v.CommissionWithTaxAmount * 100) / 100
        if (v.CommissionType !== COMMISSION_TYPE.RESELLER) {
          if (ticketUsed === TICKET_USAGE.SALE) {
            serviceCharge += parseFloat(withTaxAmount)
          } else {
            if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
              serviceCharge += withTaxAmount
            }
          }
        }
      })
      return serviceCharge
    }
  }

  const byPercentReseller = (commissions) => {
    if (commissions != null) {
      let isByPercent = false
      commissions.forEach((v) => {
        if (v.CommissionType === COMMISSION_TYPE.RESELLER) {
          if (v.CommissionUsed === COMMISSION_USED.BY_PERCENT) {
            isByPercent = true
          }
        }
      })
      return isByPercent
    }
  }

  const byPercentPlatform = (commissions) => {
    if (commissions != null) {
      let isByPercent = false
      commissions.forEach((v) => {
        if (v.CommissionType === COMMISSION_TYPE.PLATFORM) {
          if (v.CommissionUsed === COMMISSION_USED.BY_PERCENT) {
            isByPercent = true
          }
        }
      })
      return isByPercent
    }
  }

  const values = {
    Name: ticket?.Name,
    Price: ticket?.Price || 0,
    Remaining: ticket?.Remaining || 0,
    TicketPrice: getTicketPrice(ticket?.Commissions, ticket?.Cost) || 0,
    ServiceCharge:
      getServiceCharge(ticket?.Commissions, ticket?.TicketUsage) || 0,
    SaleStartDate: ticket?.SaleStartDate || moment('00:00', 'hh:mm'),
    SaleEndDate: ticket?.SaleEndDate || moment('23:30', 'HH:mm'),
    Cost: ticket?.Cost || 0,
    TicketUsage: ticket?.TicketUsage || 0,
    PaymentGatewayCommission:
      getPaymentGatewayCommission(ticket?.PaymentGatewayCommission) || 0,
    PaymentGatewayAccreditationDays:
      getPaymentGatewayAccreditationDays(
        ticket?.PaymentGatewayAccreditationDays
      ) || 0,
    Commission:
      myRound(
        ticket?.Price -
          (ticket?.Price * ticket?.PaymentGatewayCommission) / 100 -
          ticket?.Cost
      ) || 0,
    CommissionCash: ticket?.Price - ticket?.Cost,
    Active: ticket?.Active,
    AllowRemoteSell: ticket?.AllowRemoteSell || false,
    IsTransferable: ticket?.IsTransferable,
    DisabledDatesForm: disableFormDate(),
    AllowedToRole: ticket?.AllowedToRole || 0,
    MaxByReseller: ticket?.MaxByReseller || null,
    ShowRemaining: ticket?.ShowRemaining,
    ComboTickets: getComboTickets(ticket?.ComboTicketModel) || '',
    ComboTicketModel: ticket?.ComboTicketModel || [],
    Rules: ticket?.Rules || '',
    RoleAdministrator: !!(ticket?.AllowedToRole & 1),
    RoleProducer: !!(ticket?.AllowedToRole & 4),
    RoleRRPP: !!(ticket?.AllowedToRole & 8),
    MaxQuantityToSale: ticket == null ? '' : ticket.MaxQuantityToSale,
    PercentageCost: calculateProfitMargin(ticket?.Cost, ticket?.Price) || '',
    ByPercentReseller: byPercentReseller(ticket?.Commissions) || false,
    ByPercentPlatform: byPercentPlatform(ticket?.Commissions) || false,
    Billable: ticket?.Billable,
    Version: ticket?.Version,
    Commissions: ticket?.Commissions || []
  }

  const ticketUsageLabel = (usage) => {
    switch (usage) {
      case TICKET_USAGE.SALE:
        return 'Venta Online'
      case TICKET_USAGE.DRAW:
        return 'Sorteo'
      case TICKET_USAGE.CASH:
        return 'Venta en Efectivo'
      case TICKET_USAGE.INVITATION:
        return 'Invitación'
      case TICKET_USAGE.SWAP:
        return 'Canje'
      case TICKET_USAGE.COMBO:
        return 'Combo'
      case TICKET_USAGE.FREE:
        return 'Gratuito'
      case TICKET_USAGE.SERVICE_CHARGE_ONLY:
        return 'Solo Cargo por Servicio'
      default:
        return ''
    }
  }

  return (
    <>
      {event && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${event?.Title} (${event?.Id})`}
        </Typography>
      )}
      <PaperContainer
        title={`Editar Ticket (${ticketUsageLabel(ticket?.TicketUsage)})`}>
        {pendingTicket || pending || pendingEventsCombo ? (
          <CircularProgress size={24} color='secondary' />
        ) : (
          <>
            <Typography variant='subtitle2' className={classes.idTicket}>
              Id: {match.params.ticketId}
            </Typography>
            <EventTicketForm
              event={event}
              values={values}
              match={match}
              actionText='Editar'
              isEditable={false}
              drawFinalized={drawFinalized()}
              handleSubmit={handleSubmit}
              actionPending={actionPending}
              actionSuccess={actionSuccess}
              onCancel={handleRedirect}
            />
          </>
        )}
      </PaperContainer>
    </>
  )
}

export default TicketsUpdatePage
